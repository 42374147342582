import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const VoucherBody = ({purchaseOrderData,authInfo})=>{
    const classes = useStyles();
  

         let [purchaseOrder,purchaseOrderSet] = useState([])
          let [purchaseOrderDetails,purchaseOrderDetailsSet] = useState([])

          let [is_serial,is_serial_set] = useState('')
          let [is_cal_type,is_cal_type_set] = useState('')

        useEffect(()=>{
            purchaseOrderSet(purchaseOrderData.length != 0 ?  purchaseOrderData[0] : null)
            
            is_serial_set(purchaseOrderData.is_serial)
            is_cal_type_set(purchaseOrderData.is_cal_type)

            if(purchaseOrderData.details!=undefined){
                purchaseOrderDetailsSet(purchaseOrderData.details)

            }
            },[purchaseOrderData])

         


      return(
          <>
        <table className={'invoice-table'}> 
          <thead>
              <tr>
                <td align="left" width="40%">FROM ACCOUNT</td>
                <td align="left" width="40%">TO ACCOUNT</td>
                <td align="right"  width="20%">Amount</td>
              </tr>
          </thead>
          <tbody>
        
                   
                               <tr>
                                 <td align="left">{purchaseOrderData.from_acc_name} </td>
                                 <td align="left">{purchaseOrderData.to_acc_name} </td>

                                  <td align="right">{format(parseFloat(purchaseOrderData.tran_amount).toFixed(2))}</td>
                                </tr>
                 
                
           

               
              

              
          </tbody>
        </table>
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default VoucherBody;
